
import { useParams } from "react-router-dom";
import React, {useState, useEffect, useRef} from "react";
import "./Page.css";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import {sessionDataActions} from "../../../store";
import {DataGrid} from "@mui/x-data-grid";

import {
    findCheckInParticipantByCode, findCheckInParticipantByParticipantID,
    getEventDetails,
    getParticipantList, UpdateParticipantAttendance
} from "../../../services/AWS/competitionInformation/competitionInfo";
import {useDispatch, useSelector} from "react-redux";
import Stack from "@mui/material/Stack";
import moment from "moment/moment";
import config from "../../../config.json";
import TextField from "@mui/material/TextField";
const cardStyle = {
    fontFamily:"Arial",
    fontSize: "20px",
    marginLeft:"30%",
}



const CheckInPage = (props) =>{
    const [AuthorizedCheckIn, setAuthorizedCheckIn] = useState(false);
    const dispatch = useDispatch();
    const isWCALoggedin = useSelector(state => state.sessionData.isWCALoggedIn);
    const user = useSelector(state => state.sessionData.userInformation);
    const WCAuserLoggedInCheck = useSelector(state => state.sessionData.WCAuserLoggedInCheck);
    const {id} = useParams();
    const [ParticipantList, setParticipantList] = useState([]);
    const [FilteredList, setFilteredList] = useState([]);
    const [ParticipantInformation, setParticipantInformation] = useState({});
    const localStorageKey = (key) => `groups.${config.WCA_OAUTH_CLIENT_ID}.${key}`;
    const getLocalStorage = (key) => localStorage.getItem(localStorageKey(key));
    const [NameFilterValue, setNameFilterValue] = useState("");
    const [StatusBanner, setStatusBanner] = useState("");
    const [bannerColor, setBannerColor] = useState("green");
    const[ Remark, setRemark] = useState("");
    const textFieldRef = useRef(null);

    const handleButtonClick = () => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    };

    const handleUpdateButtonClick =async (e,attended) => {
        textFieldRef.current.focus();
        if (ParticipantInformation.hasOwnProperty("ID") === false) {
            return;
        }
        if (attended === true && ParticipantInformation.hasOwnProperty("attended") && ParticipantInformation.attended === true) {
            return;
        }
        if (attended === false && ParticipantInformation.hasOwnProperty("attended") && ParticipantInformation.attended === false) {
            return;
        }
        dispatch(sessionDataActions.loading());
        await UpdateParticipantAttendance(ParticipantInformation.ID,id,attended,Remark);
        setStatusBanner("Check In Updated");
        setBannerColor("green");
        setParticipantInformation({});
        setNameFilterValue("");
        setFilteredList([]);
        setRemark("");
        dispatch(sessionDataActions.loaded());
    }

    const getParticipantProperty =  (property) => {
        if (ParticipantInformation.hasOwnProperty(property)) {
            if (property === "attended") {
                return ParticipantInformation[property] ? "Checked In" : "Not Checked In";

            }
            if (property === "checkInDateTime") {
                return ParticipantInformation[property] ? moment(ParticipantInformation[property]).format("YYYY-MM-DD HH:mm:ss").toLocaleUpperCase() : "";
            }
            if (property === "gender") {
                if (ParticipantInformation[property] === "m") {
                    return "Male"
                }
                if (ParticipantInformation[property] === "f") {
                    return "Female"
                }
                return ParticipantInformation[property];
            }

            return ParticipantInformation[property];
        }
        else{
            if (property === "attended" && ParticipantInformation.hasOwnProperty("ID") ) {
                return  "Not Checked In";

            }
        }

        return "";
    }

    const columns = [
        {
            field: "Select",
            headerName: "Select",
            sortable: false,
            renderCell: (params) => {
                const onEditClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    const currentRow = params.row;
                    HandleGridSelectClick(currentRow)
                };

                return <Button onClick={onEditClick}>Select</Button>;
            }
        },
        { field: 'ID', headerName: 'ID', width: 100,visible:false },
        { field: 'UserName', headerName: 'Name', width: 400 },
        { field: 'WCAID', headerName: 'WCAID', width: 150 }]

    useEffect(() => {
        if (WCAuserLoggedInCheck === true) {
            GetCompetitionDetails(id);

        }
    }, [WCAuserLoggedInCheck]);

    const  getPartList =async (id) =>{
        const respondData = await getParticipantList(id);
        const eventList2 = respondData.list;
        setParticipantList(eventList2);

    }

    const GetCompetitionDetails = async (comp_id) => {
        dispatch(sessionDataActions.loading());
        let getcompdetails;
        if (isWCALoggedin) {
            getcompdetails = await getEventDetails(comp_id, user.email);

            //loop Register_Access
            if (getcompdetails.hasOwnProperty("Register_Access")) {
                const auth_list = getcompdetails.Register_Access.toString().split(";");
                for (var i = 0; i < auth_list.length; i++) {
                    if (auth_list[i] === user.email) {
                        setAuthorizedCheckIn(true);
                        break;
                    }
                }
            }
          await   getPartList(id);

        }
        dispatch(sessionDataActions.loaded());
    }

    const handleFilterTextChange = (e) => {
        //only take top 5 rows
        const value = e.target.value || '';
        setNameFilterValue(value);
        const lowerCaseValue = value.toLowerCase();
        const filteredData = ParticipantList.filter((row) =>
            row.UserName.toLowerCase().includes(lowerCaseValue)
        );
        filteredData.length = Math.min(filteredData.length, 5);
        setFilteredList(filteredData);
        if (value === '') {
            setFilteredList([]);
        }
    }

    const handleBarcodeFieldKeyDown = async (e) => {
        //if keycode enter then trigger search
        if (e.keyCode === 13 && e.target.value !== '') {
            dispatch(sessionDataActions.loading());
            const MYCAToken = getLocalStorage("MYCAaccessToken");
          const userData = await findCheckInParticipantByCode(e.target.value,id,MYCAToken);

          if (userData !== false){
                setParticipantInformation(userData);
                if (userData.hasOwnProperty("attended") && userData.attended === true) {
                    setBannerColor("red");
                    setStatusBanner("Participant Already Check in");
                }
                else {
                    setBannerColor("green");
                    setStatusBanner("Participant Found");
                }
              if (userData.hasOwnProperty("CheckInRemark")){
                  setRemark(userData.CheckInRemark);
              }
            }
            else {
                setBannerColor("red");
                setStatusBanner("Participant Not Found");
            }
          dispatch(sessionDataActions.loaded());
            e.target.value = '';
        }

    }

    const HandleGridSelectClick =async (row) => {
        dispatch(sessionDataActions.loading());
        const MYCAToken = getLocalStorage("MYCAaccessToken");
        const userData = await findCheckInParticipantByParticipantID(row.ID,id,MYCAToken);
        if (userData !== false){
            setParticipantInformation(userData);
            if (userData.hasOwnProperty("attended") && userData.attended === true) {
                setBannerColor("red");
                setStatusBanner("Participant Already Check in");
            }
            else {
                setBannerColor("green");
                setStatusBanner("Participant Found");
            }

            if (userData.hasOwnProperty("CheckInRemark")){
                setRemark(userData.CheckInRemark);
            }
        }
        else {
            setBannerColor("red");
            setStatusBanner("Participant Not Found");
        }

        dispatch(sessionDataActions.loaded());
    }

    const CheckIn =(
        <Box sx={cardStyle}>
            <h2>Check In</h2>
            <TextField type="text" className="inputField" placeholder="Scan barcode"
                       autoFocus style={{height: "20px", fontSize: 16}}
                       inputRef={textFieldRef}
                       onKeyDown={(e) => handleBarcodeFieldKeyDown(e)}/>
            <br/>
            <br/>
            <h4>Search By WCA Name</h4>
            <TextField style={{height: "20px", fontSize: 16}} type="text" className="inputField"
                       placeholder="Search By WCA Name"
                       onChange={(e) => handleFilterTextChange(e)} value={NameFilterValue}/>
            <br/>
            <br/>
            <DataGrid
                sx={{width: "650px", minHeight: "30px", marginTop: "17px"}}
                columnHeaderHeight={30}
                disableColumnMenu
                hideFooter
                columns={columns}
                rows={FilteredList}
                autoHeight={true}
                getRowId={(row) => row.ID}
                pageSize={100}
                pageSizeOptions={[100]}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            No participant
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="left">
                            No participant
                        </Stack>
                    )
                }}
            />
            <h2 style={{color: bannerColor}}>{StatusBanner}</h2>
            <table>
                <tr>
                    <td>Reg. Number</td>
                   <b> <td>{getParticipantProperty("RunningNumber")}</td></b>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>{getParticipantProperty("UserName")}</td>
                </tr>
                <tr>
                    <td style={{width: "300px"}}>WCA ID</td>
                    <td>{getParticipantProperty("WCAID")}</td>
                </tr>
                <tr>
                    <td>Region</td>
                    <td>{getParticipantProperty("country")}</td>
                </tr>
                <tr>
                    <td>Gender</td>
                    <td>{getParticipantProperty("gender")}</td>
                </tr>
                <tr>
                    <td>Birth Date</td>
                    <td>{getParticipantProperty("dob")}</td>
                </tr>
                <tr>
                    <td>Number Of Guest</td>
                    <td>{getParticipantProperty("GuestNumber")}</td>
                </tr>
                <tr>
                    <td>Check In Status</td>
                    <td>{getParticipantProperty("attended")}</td>
                </tr>
                <tr>
                    <td>Check In Time</td>
                    <td>{getParticipantProperty("checkInDateTime")}</td>
                </tr>
                <tr>
                    <td>T Shirt Size</td>
                    <td>{getParticipantProperty("tshirtSize")}</td>
                </tr>

            </table>
            <div>
                <h4>Remark</h4>
                <TextField style={{height: "20px", fontSize: 16}} type="text" className="inputField"
                           placeholder="Remark"
                           onChange={(e) => setRemark(e.target.value)} value={Remark}/>


            </div>
            <br/>
            <br/>
            <div>
                <Button size="large" variant="contained" style={{marginRight: "40px"}}
                        onClick={(e) => handleUpdateButtonClick(e, true)}>Check In</Button>
                <Button size="large" variant="Outlined" onClick={(e) => handleUpdateButtonClick(e, false)}>Cancel Check
                    In</Button>

            </div>
        </Box>
    )

    const NotAuthorizedPanel = (
        <Box sx={cardStyle}>
            <h1>Not Authorized</h1>
            <p>You are not authorized to check in participants</p>
            <p>Go to register page to switch account or login wca</p>
            <a href={`/competition/register/${id}`}>Register Page</a>
        </Box>
    )

    return (
        <div>
            {AuthorizedCheckIn ? CheckIn : NotAuthorizedPanel}
        </div>
    )
}

export default CheckInPage;