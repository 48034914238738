import React, { useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const NumberInput = ({ min = 0, max = 100, defaultValue = 0, onChange,id,label=null, plusDisable=false }) => {
    const [value, setValue] = useState(defaultValue);

    const handleIncrement = () => {
        const newValue = Math.min(value + 1, max);
        setValue(newValue);
        onChange && onChange(id,newValue);
    };

    const handleDecrement = () => {
        const newValue = Math.max(value - 1, min);
        setValue(newValue);
        onChange && onChange(id,newValue);
    };

    const handleChange = (event) => {
        const newValue = Math.max(Math.min(parseInt(event.target.value) || min, max), min);
        setValue(newValue);
        onChange && onChange(id,newValue);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleDecrement} disabled={value <= min} >
                <RemoveIcon />
            </IconButton>
            <TextField
                value={value}
                disabled={true}
                onChange={handleChange}
                type="number"
                inputProps={{ min, max }}
                sx={{ width: '60px', '& input': { textAlign: 'center' } }}
            />
            <IconButton onClick={handleIncrement} disabled={value >= max || plusDisable} >
                <AddIcon />
            </IconButton>
            <text>{label == null? id: label}</text>
        </Box>
    );
};

export default NumberInput;