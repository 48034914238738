import './index.css';
import React, {useEffect, useRef, useState} from "react";
import backdrop from "./image/Backdrop.png";
import keyChainImg from "./image/keychain.png";
import bundleAImg from "./image/bundleA.png";
import bundleBImg from "./image/bundleB.png";
import tshirtChart from "./image/tshirtChart.png";
import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {sessionDataActions} from "../../../store";
import {
    submitMerchantForm,
} from "../../../services/AWS/competitionInformation/competitionInfo";
import {MastereventList} from "../../../Component/Competition/CompetitionComponent";
import NumberInput from "../../../Component/Control/Control";
import {json} from "react-router-dom";

const PageSection = {
    SECTION1:"SECTION1",
    SECTION2:"SECTION2",
    SECTION3:"SECTION3",
    SECTION4:"SECTION4"
}


const getNextSection = (currentSection) => {
    window.scrollTo(0, 0)
    switch (currentSection) {
        case PageSection.SECTION1:
            return PageSection.SECTION2;
        case PageSection.SECTION2:
            return  PageSection.SECTION3;
        case PageSection.SECTION3:
            return  PageSection.SECTION4;
        default:
            return  PageSection.SECTION1
    }
}

const getPreviousSection = (currentSection) => {
    window.scrollTo(0, 0)
    switch (currentSection) {
        case PageSection.SECTION1:
            return PageSection.SECTION1;
        case PageSection.SECTION2:
            return  PageSection.SECTION1;
        case PageSection.SECTION3:
            return  PageSection.SECTION2;
        case PageSection.SECTION4:
            return  PageSection.SECTION3;
        default:
            return  PageSection.SECTION3

        
    }
}


const MerchandisePage = () => {
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [inputs, setInputs] = useState({BundleAQTY: 0, BundleBQTY: 0, keychainQTY: 0, Email: "", FullName: "", phoneNumber: ""});
    const [haveError, setHaveError] = useState(false);
    const [errorMessages, setErrorMessages] = useState("");
    const [tshirtToSelect, setTshirtToSelect] = useState(0);
    const [remainingTShirt, setRemainingTshirt] = useState(0);
    const [error, setError] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [tshirtSize, setTshirtSize] = useState({});
    const [addButtonDisable, setAddButtonDisable] = useState(false);
    const renderErrorMessage = (name) =>
        name === error.name && (
            <div style={{fontSize:20}} className="error">{error.message}</div>
        );

    const onThishirtSizeChange = (propertyName,value) => {
        const tshirtInfo = {...tshirtSize, [propertyName]: value};
        //get total tshirt selected
        let totalSelectedTshirt = 0;
        for (let key in tshirtInfo){
            totalSelectedTshirt += Number(tshirtInfo[key]);
        }

        if (totalSelectedTshirt >= tshirtToSelect){
            setAddButtonDisable(true);
        }
        else{
            setAddButtonDisable(false);
        }
        setRemainingTshirt(tshirtToSelect - totalSelectedTshirt);
        setTshirtSize(tshirtInfo);
    }

    const getInputValue = (field) =>{
        if (!inputs.hasOwnProperty(field)){
            return "";
        }
        else
            return inputs[field];
    }

    const selectTshirtSection = (
        <div>
            <h3>🎨 Select T-Shirt Size</h3>
            <img style={{width: "80%"}} src={tshirtChart} alt="tshirtChart" />

            <p> <b>Remaining Quantity:</b> {remainingTShirt}</p>

            <NumberInput onChange={onThishirtSizeChange} min={0} max={10} plusDisable={addButtonDisable} id={"XS"} />
            <NumberInput onChange={onThishirtSizeChange} min={0} max={10} plusDisable={addButtonDisable} id={"S"} />
            <NumberInput onChange={onThishirtSizeChange} min={0} max={10} plusDisable={addButtonDisable} id={"M"} />
            <NumberInput onChange={onThishirtSizeChange} min={0} max={10} plusDisable={addButtonDisable} id={"L"} />
            <NumberInput onChange={onThishirtSizeChange} min={0} max={10} plusDisable={addButtonDisable} id={"XL"} />
            <NumberInput onChange={onThishirtSizeChange} min={0} max={10} plusDisable={addButtonDisable} id={"XXL"} />
            <NumberInput onChange={onThishirtSizeChange} min={0} max={10} plusDisable={addButtonDisable} id={"XXXL"} />

        </div>
    )


    let InitialListMap = new Map();
    //map eventList to InitalMap with false value
    for (let i = 0; i < MastereventList.length; i++) {
        InitialListMap.set(MastereventList[i], false);
    }

    const [Role, setRole] = useState('');
    const [currentSection, setCurrentSection] = useState(PageSection.SECTION1);


    const handleSubmit =async (e) => {
        dispatch(sessionDataActions.loading());
        e.preventDefault();
        if (totalAmount === 0){
            setErrorMessages("Please select at least one item to purchase");
            setHaveError(true);
            dispatch(sessionDataActions.loaded());
            return;
        }
        if (remainingTShirt !== 0){
            setErrorMessages("Please select all the tshirt size");
            setHaveError(true);
            dispatch(sessionDataActions.loaded());
            return;
        }
        //construct the json payload to submit, by combining inputs and other drop downs
        let submitData = {
            Email: inputs.Email,
            Name: inputs.FullName,
            PhoneNumber: inputs.phoneNumber,
            Product: {
                BundleA: Number(inputs.BundleAQTY),
                BundleB: Number(inputs.BundleBQTY),
                Keychain: Number(inputs.keychainQTY),
            },
            TshirtSize: JSON.stringify(tshirtSize)
        }
        //submit the form
        const submitRespond = await submitMerchantForm(submitData)
        if (submitRespond.message !== "Sucess"){
            setErrorMessages(submitRespond)
            setHaveError(true);
            dispatch(sessionDataActions.loaded());
            return;
        }
        //redirect to stripe payment page
        window.location.href = "/Payment/" + submitRespond.PaymentInfo.client_secret;
    }
    const handleSectionSubmit = (e) => {
        e.preventDefault();
        setError({name: "", message: ""});

        setCurrentSection(getNextSection(currentSection,Role));
        return ;
    }


    const handleClose = (event, reason) => {
        setHaveError(false);
    };



    const Section1 = (
        <form onSubmit={handleSectionSubmit}>
            <div>
                <div style={{
                    padding: '20px',
                    backgroundColor: '#f9f9f9',
                    color: '#333',
                    fontFamily: 'Arial, sans-serif'
                }}>
                    <h1 style={{color: '#e91e63'}}>
                        🎉 <span style={{fontWeight: 'bold'}}>Pre-Order NOW for Exclusive WCA Asian Championship 2024 Merchandise!</span> 🎉
                    </h1>

                    <p>
                        This is your chance to secure <span style={{fontWeight: 'bold'}}>exclusive merch</span> before
                        the big event! Don’t wait until it’s too late – once these items are gone, they’re gone forever!
                    </p>

                    <h2>💥 Here’s What You Can Grab Right Now! 💥</h2>

                    <div style={{
                        margin: '10px 0',
                        padding: '10px',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '5px'
                    }}>
                        <p>🔹 <span style={{fontWeight: 'bold'}}>Limited Edition AC Mascot Plushie Keychain</span> –
                            RM159</p>
                        <p>Carry the charm of the AC Mascot with you everywhere! 🧸 Soft, adorable, and only available
                            for this event.</p>
                    </div>

                    <div style={{
                        margin: '10px 0',
                        padding: '10px',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '5px'
                    }}>
                        <p>🔹 <span style={{fontWeight: 'bold'}}>Bundle A – R̶M̶ ̶4̶1̶9̶ NOW ONLY: RM 300</span></p>
                        <ul>
                            <li>One (1) Customizable CoolDry-Fit Shirt 🏆</li>
                            <li>One (1) Limited Edition AC Mascot Plushie Keychain 🧸</li>
                            <li><span style={{fontWeight: 'bold'}}>FREE</span> AC 2024 Collector’s Edition Set Pins 🏅
                            </li>
                        </ul>
                    </div>

                    <div style={{
                        margin: '10px 0',
                        padding: '10px',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '5px'
                    }}>
                        <p>🔹 <span style={{fontWeight: 'bold'}}>Bundle B – R̶M̶ ̶4̶9̶9̶ NOW ONLY: RM 350</span></p>
                        <ul>
                            <li>One (1) Customizable Tote Bag 🎒</li>
                            <li>One (1) Customizable Cube Pouch 🔑</li>
                            <li>One (1) AC 2024 Collector’s Edition Cube Mat 🎲</li>
                            <li><span style={{fontWeight: 'bold'}}>FREE</span> AC 2024 Collector’s Edition Set Pins 🏅
                            </li>
                        </ul>
                    </div>

                    <h2>🌟 Why Pre-Order? 🌟</h2>

                    <ul>
                        <li>✨ <span style={{fontWeight: 'bold'}}>Exclusive Customization!</span> – At the event, use
                            our <span style={{fontWeight: 'bold'}}>heat printing stations</span> to personalize your
                            gear with stickers, flags, or any design you want! Your gear, your style! 🎨
                        </li>
                        <li>✨ <span style={{fontWeight: 'bold'}}>Priority Access & Skip the Queue!</span> – Pre-orders
                            get VIP treatment. Be first in line to claim and design your custom gear on <span
                                style={{fontWeight: 'bold'}}>Day 1</span> of the event.
                        </li>
                        <li>✨ <span style={{fontWeight: 'bold'}}>FREE Collector’s Edition Pins:Not for sale anywhere</span> – these pins come
                            <span style={{fontWeight: 'bold'}}> FREE</span> with limited units available only! 🛡️
                        </li>
                        <li>✨ <span style={{fontWeight: 'bold'}}>Offer for Pre-order only!</span> – These offers will only be available for pre-orders only!
                            Once the pre-order period has ended, we will revert back to our original price. So grab your bundle now before the offer lapse!
                        </li>
                    </ul>

                    <div style={{
                        marginTop: '20px',
                        padding: '15px',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '5px'
                    }}>
                        <h2>🛒 Order Deadline:</h2>
                        <p>Pre-orders close on 15 October 2024 (Tuesday), 8 PM GMT+8!</p>
                        <p>Don’t miss your chance to get these incredible savings and secure your spot for
                            customization!</p>
                    </div>

                    <div style={{
                        marginTop: '20px',
                        padding: '15px',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '5px'
                    }}>
                        <h2>📍 Event Details:</h2>
                        <p>Pick up your pre-orders at our Merch Booth during the event (1st - 4th November 2024).</p>
                        <p>The first day is reserved exclusively for <span style={{fontWeight: 'bold'}}>pre-order collections</span>!
                        </p>
                    </div>

                    <div style={{
                        marginTop: '20px',
                        padding: '15px',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '5px'
                    }}>
                        <h2>🚨 Time Is Running Out! 🚨</h2>
                        <p>Secure your exclusive WCA merch and stand out at the event.</p>
                        <p><span style={{fontWeight: 'bold'}}>LIMITED UNITS AVAILABLE</span> – act now and <span
                            style={{fontWeight: 'bold'}}>pre-order before it’s too late</span>! ⏳</p>
                    </div>

                </div>
                <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                    <Button variant="contained" color="primary" type="submit">
                        Next
                    </Button>
                </Box>
            </div>
        </form>
    )

    const Section2 = (
        <form onSubmit={handleSubmit}>
            <div className='cardStyle'>
                <p>🔹 **Limited Edition AC Mascot Plushie Keychain** </p>
                <img src={keyChainImg} alt="tshirtChart" className="tshirtChart"/>
            </div>
            <div className='cardStyle'>
                <p>🔹 Bundle A</p>
                <img src={bundleAImg} alt="tshirtChart" className="tshirtChart"/>
            </div>
            <div className='cardStyle'>
                <p>🔹Bundle B </p>
                <img src={bundleBImg} alt="tshirtChart" className="tshirtChart"/>
            </div>
            <div className='cardStyle'>
                <p><b>Order Details</b></p>
                <table>
                    <tr style={{height: "70px"}}>
                        <td>
                            <TextField name="keychainQTY" label="0-100" type="number" required style={{width: "100px"}}
                                       value={getInputValue("keychainQTY")}
                                       InputProps={{inputProps: {min: 0, max: 100}}}
                                       onChange={(event) => handleFormChange(event, "keychainQTY")}/></td>
                        <td>AC Mascot Keychain RM 159 Per Quantity</td>

                    </tr>
                    <tr style={{height: "70px"}}>
                        <td>
                            <TextField name="BundleAQTY" label="0-100" type="number" required style={{width: "100px"}}
                                       value={getInputValue("BundleAQTY")}
                                       InputProps={{inputProps: {min: 0, max: 100}}}
                                       onChange={(event) => handleFormChange(event, "BundleAQTY")}/></td>
                        <td>Bundle A RM300 Per Quantity</td>

                    </tr>

                    <tr style={{height: "70px"}}>
                        <td>
                            <TextField name="BundleBQTY" label="0-100" type="number" required style={{width: "100px"}}
                                       value={getInputValue("BundleBQTY")}
                                       InputProps={{inputProps: {min: 0, max: 100}}}
                                       onChange={(event) => handleFormChange(event, "BundleBQTY")}/></td>
                        <td>Bundle B RM350 Per Quantity</td>
                    </tr>
                </table>
                {/* eslint-disable-next-line no-use-before-define */}
                {tshirtToSelect > 0 && selectTshirtSection}
                <h3>
                    Total Amount: RM {totalAmount}
                </h3>
                <div className="input-container">
                    <p><b>Email Address</b></p>
                    <TextField name="Email" label="valid email address" type="email" required
                               value={getInputValue("Email")}
                               onChange={(event) => handleFormChange(event, "Email")}/>
                </div>
                <div className="input-container">
                    <p><b>Full Name</b></p>
                    <TextField name="FullName" label="Full Name" type="text" required
                               value={getInputValue("FullName")}
                               onChange={(event) => handleFormChange(event, "FullName")}/>
                </div>
                <div className="input-container">
                    <p><b>Contact Number (Whatsapp)</b></p>
                    <TextField name="phoneNumber" label="i.e +60123456789" type="text" required
                               value={getInputValue("phoneNumber")}
                               onChange={(event) => handleFormChange(event, "phoneNumber")}/>
                </div>

                <FormControlLabel
                    control={
                        <Checkbox required={true}
                                  name="six"/>
                    }
                    label="You need to place your pre-order by 15 October 2024 (Tuesday), 8 PM GMT+8. We have limited stock, so it's first come, first served. Once sold out, no more orders will be accepted."
                />
                <br/>
                <br/>
                <FormControlLabel
                    control={
                        <Checkbox required={true}
                                  name="six"/>
                    }
                    label="All pre-orders are final. Unfortunately, we won’t be able to process refunds or exchanges, especially for customizable items once they’re personalized at the event."
                />
                <br/>
                <br/>
                <FormControlLabel
                    control={
                        <Checkbox required={true}
                                  name="six"/>
                    }
                    label="Pre-orders must be collected at the Merch Booth between 1st - 4th November 2024. Pre-order customers get priority on 1st November 2024. If items aren’t picked up by the end of the event, they cannot be refunded."
                />
                <br/>
                <br/>
            </div>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                <Button variant="outlined" color="primary"
                        onClick={() => setCurrentSection(getPreviousSection(currentSection))}>
                    Back
                </Button>
                <Button variant="contained" color="primary" type="submit">
                    Make Payment
                </Button>
            </Box>

        </form>
    )

    const renderForm = (
        <div className="app">
            {currentSection === PageSection.SECTION1 && Section1}
            {currentSection === PageSection.SECTION2 && Section2}

        </div>

    )
    const action = (
        <>
            <Button color="secondary" size="small" onClick={handleClose}>
                Close
            </Button>
        </>
    );

    const handleFormChange = (event, name) => {

        let value = event.target.value;

        if (name === "BundleAQTY" || name === "BundleBQTY" || name === "keychainQTY") {
            let inputsAmount = inputs;
            inputsAmount[name] = value;

            setTotalAmount(Number(inputsAmount.BundleAQTY) * 300 + Number(inputsAmount.BundleBQTY) * 350 + Number(inputsAmount.keychainQTY) * 159);
            const totalTshirttoSelect = Number(inputsAmount.BundleAQTY) + Number(inputsAmount.BundleBQTY);
            setTshirtToSelect(totalTshirttoSelect);
            const remainingTshirt = totalTshirttoSelect - Object.values(tshirtSize).reduce((a, b) => a + b, 0);
            setRemainingTshirt(remainingTshirt);
            if (remainingTshirt <= 0) {
                setAddButtonDisable(true);
            }
            else
                setAddButtonDisable(false);

            if (totalTshirttoSelect === 0) {
                //set all size to 0
                let tshirtSizeState = tshirtSize;
                for (let key in tshirtSizeState) {
                    tshirtSizeState[key] = 0;
                }
                setTshirtSize(tshirtSizeState);
            }
        }

        setInputs(values => ({...values, [name]: value}))
    }

    return (
        <div className="app">
            <div className="register-form">
                <img src={backdrop} alt="backdrop" className="backdrop"/>
                <div className="title">[Pre-Order] Rubik's WCA Asian Championship 2024 Official Merchandise</div>
                {!isSubmitted && <div>{renderForm}</div>}
                {isSubmitted &&
                    <div className="submitted">Your application has been submitted successfully! <br/> You will be
                        contacted if you been shortlisted.<br/> You may close this window now</div>}
            </div>
            <Snackbar
                open={haveError}
                autoHideDuration={6000}
                onClose={handleClose}
                message={errorMessages}
                action={action}
            />
        </div>
    )
}



export default MerchandisePage;





