import {CompNavBtnLink} from "../masterPagecomponent/NavbarElements";
import "./CompetitonComponent.css";
import Grid from '@mui/material/Grid';
import React from "react";
import "@cubing/icons";
import {useSelector} from "react-redux";


const CompetitionMenuItem = ( props ) => {
    return (

            <div style={{display:"flex",flexDirection:"Horizontal",gap: "10px"}}>
                {props.competition  === "RubiksWCAAsianChampionship2024" && <NavigationButton url={`/RubiksWCAAsianChampionship2024/Home`} menuText="Main"/>}
                <NavigationButton url={`/competition/info/${props.competition}`} menuText="Details" />
                <NavigationButton url={`/competition/register/${props.competition}`} menuText="Register" />
                <NavigationButton url={`/competition/ParticipantList/${props.competition}`} menuText="Participant" />

            </div>

    )

}

const NavigationButton = ( props) => {
    const handleButtongClick = (url) => {
        //navigate to the url
        window.location.href = url;
    }
    return (
        <div>
            <CompNavBtnLink
                    onClick={() => handleButtongClick(props.url)}>{props.menuText}</CompNavBtnLink>

        </div>
    )
}
const EventIconComponent = (props) => {
    if (props.events === undefined || props.events === null) {
        return <div></div>
    }
    let eventList = props.events.split(",");

    //sort by master event list sequence
    eventList.sort((a,b) => MastereventList.indexOf(a) - MastereventList.indexOf
    (b));
    return (
        <Grid container spacing={0}>
            {eventList.map((event) => {
                return (
                    <Grid item  xs={2} md={1}>
                        {RenderEventIcon(event)}
                    </Grid>
                )
            })}

        </Grid>
    )
}
const RenderEventIcon = (event) => {
    // populate following list to if condition: "2x2x2", "3x3x3", "4x4x4", "5x5x5", "6x6x6", "7x7x7", "3x3x3 Blindfolded", "3x3x3 Fewest Moves", "3x3x3 One-Handed", "Clock", "Megaminx", "Pyraminx", "Skewb", "Square-1", "4x4x4 Blindfolded", "5x5x5 Blindfolded", "3x3x3 Multi-Blind"
    if (event === "3x3x3") {
        return <span title={event} className="cubing-icon event-333"></span>
    }
    if (event === "2x2x2") {
        return <span title={event} className="cubing-icon event-222"></span>
    }
    if (event === "4x4x4") {
        return <span title={event} className="cubing-icon event-444"></span>
    }
    if (event === "5x5x5") {
        return <span title={event} className="cubing-icon event-555"></span>
    }
    if (event === "6x6x6") {
        return <span title={event} className="cubing-icon event-666"></span>
    }
    if (event === "7x7x7") {
        return <span title={event} className="cubing-icon event-777"></span>
    }
    if (event === "3x3x3 Blindfolded") {
        return <span title={event} className="cubing-icon event-333bf"></span>
    }
    if (event === "3x3x3 Fewest Moves") {
        return <span title={event} className="cubing-icon event-333fm"></span>
    }
    if (event === "3x3x3 One-Handed") {
        return <span title={event} className="cubing-icon event-333oh"></span>
    }
    if (event === "Clock") {
        return <span title={event} className="cubing-icon event-clock"></span>
    }
    if (event === "Megaminx") {
        return <span title={event} className="cubing-icon event-minx"></span>
    }
    if (event === "Pyraminx") {
        return <span title={event} className="cubing-icon event-pyram"></span>
    }
    if (event === "Skewb") {
        return <span title={event} className="cubing-icon event-skewb"></span>
    }
    if (event === "Square-1") {
        return <span title={event} className="cubing-icon event-sq1"></span>
    }
    if (event === "4x4x4 Blindfolded") {
        return <span title={event} className="cubing-icon event-444bf"></span>
    }
    if (event === "5x5x5 Blindfolded") {
        return <span title={event} className="cubing-icon event-555bf"></span>
    }
    if (event === "3x3x3 Multi-Blind") {
        return <span title={event} className="cubing-icon event-333mbf"></span>
    }


}
const MastereventList = ["2x2x2", "3x3x3", "4x4x4", "5x5x5", "6x6x6", "7x7x7", "3x3x3 Blindfolded", "3x3x3 Fewest Moves", "3x3x3 One-Handed", "Clock", "Megaminx", "Pyraminx", "Skewb", "Square-1", "4x4x4 Blindfolded", "5x5x5 Blindfolded", "3x3x3 Multi-Blind"];
export {CompetitionMenuItem,EventIconComponent,MastereventList};