import {Amplify, Auth} from 'aws-amplify';
import config from "./config.json";
import {useDispatch, useSelector} from 'react-redux';
import {useAuth} from './services/WCAAuth/WCAAuthService';
import './App.css';
import Navbar from './Component/masterPagecomponent/NavBar';
import {Route, Routes, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import HomePage from './Pages/Home/homePage';
import EventPage from './Pages/Event/EventPage';
import CompInfoPage from './Pages/CompetitionPage/Info/CompetitionPage';
import RegistrationPage from './Pages/CompetitionPage/Registration/registrationPage';
import UserRegisterPage from './Pages/userRegistration/Register';
import VerifyEmailPage from './Pages/EmailVerification/EmailVerification';
import TermsAndConditions from './Pages/Information/termsAndCons/TermsAndCons';
import RefundPolicy from './Pages/Information/refundPolicy/RefundPolicy';
import PrivacyPolicyPage from './Pages/Information/privacy Policy/PrivacyPolicy';
import ParticipantListPage from './Pages/CompetitionPage/PartitcipantList/ParticipantListPage';
import ContactPage from './Pages/Information/contact/Contact';
import AboutUsPage from './Pages/Information/AboutUs/aboutUs';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import PaymentComponent from './Pages/CompetitionPage/payment/paymentComponent';
import PaymentResultPage from './Pages/CompetitionPage/payment/paymentResultComponent';
import PaymentTimeoutPage from './Component/paymentTimeout/TimeoutPage';
import useWindowsDimensionHook from './hooks/useWindowsDimensionHook';
import LoadingOverlay from "./Pages/loadingPage/LoadingPage";
import {MemberPortal} from "./Pages/membership/portal/MemberPortal";
import AuthCheckPage from "./Component/LoginControl/AuthCheck";
import ResponsiveLogin from "./Pages/login/loginPage";
import {PurchaseMemberPage} from "./Pages/membership/purchaseMember/PurchaseMemberPage";
import {ForgetPasswordPage} from "./Pages/membership/forgetPassword/ForgetPassword";
import {sessionDataActions} from "./store";
import Footer from "./Component/masterPagecomponent/Footer";
import AsianChampHomePage from "./Pages/Ac2024/homepage/HomePage";
import PreRegistrationPage from "./Pages/CompetitionPage/whiteListRegistration/WhiteListRegistrationPage";
import StaffRegisterPage from "./Pages/Ac2024/StaffRegister/StaffRegister";
import CheckInPage from "./Pages/CompetitionPage/CheckInPanel/Page";
import MerchandisePage from "./Pages/Ac2024/merchandiseForm/Page";

function App() {
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const userpool = config.UserPool;
    const AWSRegion = config.AWSREGION;
    const {justLoggedIn, previousPage, setjustLoggedIn} = useAuth();
    const [currentCompID, setCurrentCompID] = useState("");

    const setCompetitionID = (comp_id) => {
        setCurrentCompID(comp_id);
    };
    Amplify.configure({
        Auth: {
            userPoolId: userpool.id, //UserPool ID
            region: AWSRegion,
            userPoolWebClientId: userpool.webclient //WebClientId
        }
    });
    const navigate = useNavigate();
    const isLoading = useSelector(state => state.sessionData.isLoading);
    const WCAAuthloading = useSelector(state => state.sessionData.WCAAuthloading);
    const [isToolbarVisible, setIsToolbarVisible] = useState(false);


    const handleClose = (event, reason) => {
        setjustLoggedIn(false);
    };
    const GetloginStatus = async () => {

        try {
            // get login status for myca aws user account
            const AuthInfo = await Auth.currentAuthenticatedUser();

            if (AuthInfo.username === undefined) {
                dispatch(sessionDataActions.logOut());
            } else {
                await Auth.currentSession().then(res => {
                    dispatch(sessionDataActions.loggedIn(res.idToken.jwtToken));
                })

                setUsername(AuthInfo.attributes.given_name);
            }
        } catch {
            dispatch(sessionDataActions.logOut());

        }
    }
    useEffect(() => {
        GetloginStatus();
        const currentPage = window.location.pathname;
        if (currentPage.includes("/competition/register/") || currentPage.includes("/Competition/Info/") || currentPage.includes("/Competition/register/") || currentPage.includes("/competition/ParticipantList/")) {
            setIsToolbarVisible(true);
        } else {
            setIsToolbarVisible(false);
        }
    }, []);
    const action = (
        <>
            <Button color="secondary" size="small" onClick={handleClose}>
                Close
            </Button>
        </>
    );
    return (


        <div className='Main'>
            <Navbar username={username} isVisible={isToolbarVisible} compID={currentCompID} />
            <div style={{minHeight:"100vh"}}>
                <Routes>
                    <Route path="/home" element={<HomePage/>}/>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/login" element={<ResponsiveLogin/>}/>
                    <Route path="/forgotPassword" element={<ForgetPasswordPage/>}/>
                    <Route path="/register" element={<UserRegisterPage/>}/>
                    <Route path="/events" element={<EventPage/>}/>
                    <Route path="/VerifyEmail/:id" element={<VerifyEmailPage/>}/>
                    <Route path="/VerifyEmail" element={<VerifyEmailPage/>}/>
                    <Route path="/competition/ParticipantList/:id" element={<ParticipantListPage setCompetitionID={setCompetitionID}/>}/>
                    <Route path="/competition/Payment/Result" element={<PaymentResultPage/>}/>
                    <Route path="/competition/Payment/:paymentKey" element={<PaymentComponent/>}/>
                    <Route path="/memberShip/Payment/:paymentKey" element={<PaymentComponent/>}/>
                    <Route path="/Payment/:paymentKey" element={<PaymentComponent/>}/>
                    <Route path="/Competition/register/:id" element={<RegistrationPage setCompetitionID={setCompetitionID}/>}/>
                    <Route path="/Competition/CheckInPanel/:id" element={<CheckInPage setCompetitionID={setCompetitionID}/>}/>
                    <Route path="/Competition/Preregister/:id" element={<PreRegistrationPage setCompetitionID={setCompetitionID}/>}/>
                    <Route path="/Competition/Info/:id" element={<CompInfoPage setCompetitionID={setCompetitionID} isVisible={true}/>}/>
                    <Route path="/TermsAndConditions" element={<TermsAndConditions/>}/>
                    <Route path="/RefundPolicy" element={<RefundPolicy/>}/>
                    <Route path="/PrivacyPolicy" element={<PrivacyPolicyPage/>}/>
                    <Route path="/Contact" element={<ContactPage/>}/>
                    <Route path="/aboutus" element={<AboutUsPage/>}/>
                    <Route path="/paymentTimeout" element={<PaymentTimeoutPage/>}/>
                    <Route path="/memberProfile" element={<AuthCheckPage><MemberPortal/></AuthCheckPage>}/>
                    <Route path="/subscribeMember" element={<AuthCheckPage><PurchaseMemberPage/></AuthCheckPage>}/>
                    {/* asian championship 2024 dedicated page */}
                    <Route path="/RubiksWCAAsianChampionship2024/Home" element={<AsianChampHomePage/>}/>
                    <Route path="/RubiksWCAAsianChampionship2024/StaffRegisterClosed" element={<StaffRegisterPage/>}/>
                    <Route path="/RubiksWCAAsianChampionship2024/MerchandiseForm" element={<MerchandisePage/>}/>
                </Routes>
            </div>
            <Footer/>
            <Snackbar
                open={justLoggedIn}
                autoHideDuration={6000}
                onClose={handleClose}
                message="You have successfully logged in to WCA Account"
                action={action}
            />
            <LoadingOverlay isLoading={(isLoading||WCAAuthloading)} />
        </div>


    );
}

export default App;
