import { retry } from '@aws-amplify/core';
import { stepConnectorClasses } from '@mui/material';
import axios from 'axios';
import React from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import config from "../../../config.json";


const options = {
    headers: {"Access-Control-Allow-Origin": "*"}
  };

const localStorageKey = (key) => `groups.${config.WCA_OAUTH_CLIENT_ID}.${key}`;
const getLocalStorage = (key) => localStorage.getItem(localStorageKey(key));
const MYCAToken = getLocalStorage("MYCAaccessToken");
  
const getEventList =async (isActive) => {
    
const baseURL = config.AWSPUBLICAPI + "GetCompetitionList";
let APIReturn;
 await axios
.post(baseURL, {requestData:{ActiveStatus: isActive}}).then((response) => {
    APIReturn = response;

  });
  return APIReturn.data;
};

const getEventDetails =async (eventid,userEmail) =>{
    const baseURL = config.AWSPUBLICAPI + "GetCompetitionDetails";
let APIReturn;
 await axios
.post(baseURL, {requestData:{CompetitionID: eventid,email:userEmail}}).then((response) => {
    APIReturn = response;
  });
  return APIReturn.data;
};
const getEventDetailsGeneral =async (eventid) =>{
    const baseURL = config.AWSPUBLICAPI + "GetCompetitionDetails";
let APIReturn;
 await axios
.post(baseURL, {requestData:{CompetitionID: eventid}}).then((response) => {
    APIReturn = response;
  });
  return APIReturn.data;
};

    const  getParticipantList =async (competition_ID) =>{
        const baseURL = config.AWSPUBLICAPI + "GetParticipantList";
        let APIReturn;
         await axios
        .post(baseURL, {requestData:{CompetitionID: competition_ID}}).then((response) => {
            APIReturn =  response;
          });

           return await APIReturn.data;
        };
    const  registerCompetitor =async (competition_ID,events,GuestNumber,PaperCertificate,userToken,
                                      participantId,AWSAuth,editParticipant,tshirtSize,compLocation,paymentMethod,phoneNumber) =>{
        const baseURL = config.AWSUSERAPI + "registerCompetitionV2";
        let APIReturn;
        try {
            await axios
                .post(baseURL, { requestData: { competition_ID: competition_ID,
                        events: events,
                        GuestNumber: GuestNumber,
                        PaperCertificate: PaperCertificate,
                        participantId: participantId,
                        AWSAuth: AWSAuth,
                        editParticipant: editParticipant,
                        tshirtSize:tshirtSize,
                        compLocation:compLocation,
                        paymentMethod:paymentMethod,
                        phoneNumber:phoneNumber
                    } }, { headers: { "Authorization": userToken } }).then((response) => {
                    APIReturn = response;
                });

            return await APIReturn.data;
        }
        catch (error) { 
            return "There is error when submitting, please try again later"
        }

        };

    const submitStaffApplication =async (formData,userToken) => {
        const baseURL = config.AWSUSERAPI + "submitStaffApplication";
        let APIReturn;
        try {
            await axios
                .post(baseURL, {requestData: formData}, {headers: {"Authorization": userToken}}).then((response) => {
                    APIReturn = response;
                });

            return await APIReturn.data;
        } catch (error) {
            return "There is error when submitting, please try again later"
        }

    };
const submitMerchantForm =async (formData) =>{
    const baseURL = config.AWSPUBLICAPI + "submitMerchantForm";
    let APIReturn;
    try {
        await axios
            .post(baseURL, { requestData: formData }).then((response) => {
                APIReturn = response;
            });

        return await APIReturn.data;
    }
    catch (error) {
        return "There is error when submitting, please try again later"
    }

}

const checkStaffFormSubmitted =async (wcaid,competitonid,userToken) =>{
    const baseURL = config.AWSUSERAPI + "CheckStaffFormSubmitted";
    let APIReturn;
    try {
        await axios
            .post(baseURL, { requestData: {wca_id:wcaid,competition_ID:competitonid} }, { headers: { "Authorization": userToken } }).then((response) => {
                APIReturn = response;
            });

        return await APIReturn.data;
    }
    catch (error) {
        return false;
    }

}
const findCheckInParticipantByCode =async (encryptData,competitonid,userToken) =>{
    const baseURL = config.AWSUSERAPI + "FindParticipantInfoCheckIn";
    let APIReturn;
    try {
        await axios
            .post(baseURL, { requestData: {encryptData:encryptData,competitionID:competitonid} }, { headers: { "Authorization": userToken } }).then((response) => {
                APIReturn = response;
            });
            //return false if status code not 200
            if (APIReturn.status !== 200) {
                return false;
            }
        return await APIReturn.data;
    }
    catch (error) {
        return false;
    }
}

const findCheckInParticipantByParticipantID =async (participantID,competitonid,userToken) =>{
    const baseURL = config.AWSUSERAPI + "FindParticipantInfoCheckIn";
    let APIReturn;
    try {
        await axios
            .post(baseURL, { requestData: {ParticipantID:participantID,competitionID:competitonid} }, { headers: { "Authorization": userToken } }).then((response) => {
                APIReturn = response;
            });
        //return false if status code not 200
        if (APIReturn.status !== 200) {
            return false;
        }
        return await APIReturn.data;
    }
    catch (error) {
        return "There is error when getting data, please try again later"
    }
}

const UpdateParticipantAttendance =async (participantID,competitionID,attended,Remark) =>{
    const baseURL = config.AWSUSERAPI + "UpdateParticipantAttendance";
    let APIReturn;
    try {
        await axios
            .post(baseURL, { requestData: {participantID,competitionID,attended,Remark} }, { headers: { "Authorization": MYCAToken } }).then((response) => {
                APIReturn = response;
            });
        //return false if status code not 200
        if (APIReturn.status !== 200) {
            return false;
        }
        return await APIReturn.data;
    }
    catch (error) {
        return "There is error when getting data, please try again later"
    }
}


        const  makeCompPayment =async (competition_ID,email,userToken) =>{
            const baseURL = config.AWSUSERAPI + "MakeCompPayment";
            let APIReturn;
            try {
                await axios
            .post(baseURL, {requestData:{CompetitionID: competition_ID,email:email}},{headers: {"Authorization": userToken}}).then((response) => {
                APIReturn =  response;
                });
    
                return await APIReturn.data;
            }
            catch (error) {
                return {message:"registration is full, please try again later"}
            }
            };
const  makeCompCurlecPayment =async (competition_ID,email,userToken) =>{
    const baseURL = config.AWSUSERAPI + "MakeCompPaymentCurlec";
    let APIReturn;
    try {
        await axios
            .post(baseURL, {requestData:{CompetitionID: competition_ID,email:email}},{headers: {"Authorization": userToken}}).then((response) => {
                APIReturn =  response;
            });

        return await APIReturn.data;
    }
    catch (error) {
        return await {message:"registration is full, please try again later"}
    }
};

export  {submitMerchantForm,UpdateParticipantAttendance,findCheckInParticipantByCode,findCheckInParticipantByParticipantID, checkStaffFormSubmitted,submitStaffApplication,makeCompCurlecPayment,getEventList,getEventDetails,getParticipantList,registerCompetitor,makeCompPayment,getEventDetailsGeneral};